<script setup lang="ts">
import { useHead } from '#imports'

// would expect this to exist already, but can't find a type for it??
export interface NuxtError {
  url?: string
  statusCode: number
  statusMessage: string
  message: string
}
export interface Props {
  error: NuxtError
}

const props = withDefaults(defineProps<Props>(), {
  error: () => ({
    url: undefined,
    statusCode: 500,
    statusMessage: 'An error occurred in the application',
    message: 'Unknown error',
  }),
})

const handleError = () => clearError({ redirect: '/' })

useHead({
  title: `${props.error.statusCode} - ${props.error.statusMessage}`,
  script: [],
})
</script>

<template lang="pug">
div(class="flex flex-col w-full min-h-screen justify-center items-center")
  div(class="card bg-base-200 shadow-lg w-1/2")
    div(class="card-body items-center text-center gap-4")
      div(class="card-title") Ooops...
      span Error {{ error.statusCode }}
      span {{ error.statusMessage || error.message }}
      hr(class="h-px w-full my-4 bg-gray-200 border-0 dark:bg-gray-700")
      div(class="card-actions")
        button(class="btn btn-outline btn-info" @click="handleError") Return to Site

  div(class="invisible")
    pre
      code {{ error }}
</template>
