import {
  moveImageOnCreate,
  removeProductImage,
  uploadProductImage,
} from '~~/services/ProductServices'

export type SupabaseUploadResponse = ReturnType<typeof uploadProductImage>
export type SupabaseRemoveResponse = ReturnType<typeof removeProductImage>
export type SupabaseMoveResponse = ReturnType<typeof moveImageOnCreate>

export interface FileUploadFunctions {
  onUpload: () => SupabaseUploadResponse
  onUploadSuccess: ((path: string) => Promise<any>) | undefined
  onRemove: () => SupabaseRemoveResponse
  onRemoveSuccess: () => Promise<any>
  onMove: () => SupabaseMoveResponse
  onMoveSuccess: (path: string) => Promise<any>
  fileFetcher: (path: string) => { data: { publicUrl: string } }
}

export function useFileUpload() {
  const uploadPending = ref(false)
  const uploadedPath = ref<string | undefined>(undefined)

  const uploadFile = async (
    onUpload: FileUploadFunctions['onUpload'],
    onUploadSuccess: FileUploadFunctions['onUploadSuccess']
  ) => {
    try {
      uploadPending.value = true
      const response = await onUpload()
      if (response.data) {
        uploadedPath.value = response.data.path
        if (onUploadSuccess) {
          await onUploadSuccess(response.data.path)
          uploadedPath.value = undefined
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      uploadPending.value = false
    }
  }

  const getFileUrl = (
    path: string | null | undefined,
    fileFetcher: FileUploadFunctions['fileFetcher']
  ) => {
    return path ? fileFetcher(path).data?.publicUrl || null : null
  }

  const removeFile = async (
    onRemove: FileUploadFunctions['onRemove'],
    onRemoveSuccess: FileUploadFunctions['onRemoveSuccess']
  ) => {
    const response = await onRemove()
    if (!response.error) {
      await onRemoveSuccess()
      uploadedPath.value = undefined
    }
  }

  const moveFile = async (
    onMove: FileUploadFunctions['onMove'],
    onMoveSuccess: FileUploadFunctions['onMoveSuccess']
  ) => {
    const movedPath = await onMove()
    if (movedPath) await onMoveSuccess(movedPath)
    uploadedPath.value = undefined
  }

  return {
    uploadPending,
    uploadedPath,
    uploadFile,
    getFileUrl,
    removeFile,
    moveFile,
  }
}
