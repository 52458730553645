import type { Database } from '@openesg/supabase'
import { PROFILE_TABLE_NAME } from '@openesg/supabase/constants/tableNames'
import type { ProfileType } from '@openesg/supabase/models'

export const fetchUserProfile = () => {
  return useSupabaseClient<Database>()
    .from(PROFILE_TABLE_NAME)
    .select()
    .limit(1)
    .maybeSingle()
}

export const updateUserProfile = (value: ProfileType['Insert']) => {
  return useSupabaseClient<Database>()
    .from(PROFILE_TABLE_NAME)
    .upsert({ ...value })
    .select()
    .maybeSingle()
}
