import type { Database } from '@openesg/supabase'
import { COMPANY_TABLE_NAME } from '@openesg/supabase/constants/tableNames'
import { type CompanyTypeRow, type CompanyType } from '@openesg/supabase/models'

export const fetchOwnedCompany = () => {
  return useSupabaseClient<Database>()
    .from(COMPANY_TABLE_NAME)
    .select(`*`)
    .limit(1)
    .maybeSingle<CompanyTypeRow>()
}

export const saveOwnedCompany = (value: CompanyType['Insert']) => {
  return $fetch('/api/companies/', {
    method: 'POST',
    body: { ...value },
  })
}

export const updateOwnedCompany = (value: CompanyType['Update']) => {
  // TODO: after queue system added, update questions from front and only update via queue system.
  // return useSupabaseClient<Database>()
  //   .from(TABLE_NAME)
  //   .upsert({ ...value })
  //   .select()
  //   .maybeSingle()
  return $fetch(`/api/companies`, {
    method: 'PUT',
    body: { ...value },
  })
}

export const checkIsSlugValid = (slug: string) =>
  $fetch(`/api/companies/slugs/${slug}/is-available`, { method: 'GET' })

export const fetchPublicData = (companySlug: CompanyTypeRow['slug']) =>
  $fetch(`/api/public/results/${companySlug}`, {
    method: 'GET',
  })

export const uploadCompanyLogo = (
  companyId: CompanyTypeRow['id'],
  currentFile: File
) => {
  return useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .upload(`${companyId}/company/logo/${currentFile.name}`, currentFile)
}

export const fetchCompanyLogo = (url: string) => {
  return useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .getPublicUrl(url)
}

export const removeCompanyLogo = (url: string) => {
  return useSupabaseClient<Database>()
    .storage.from('publicUploads')
    .remove([url])
}
