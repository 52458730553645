<template lang="pug">
NuxtLayout#app-layout
  NuxtPage
</template>

<script lang="ts" setup>
import defaultImage from '~~/assets/img/share.jpg'

usePageMeta({
  name: 'OpenESG',
  title: 'Welcome',
  description:
    "The world's first open, transparent ESG reporting system for products and companies.",
  image: defaultImage,
})
</script>
