import { MaybeRef, get } from '@vueuse/core'

export interface PageMeta {
  name?: MaybeRef<string>
  title?: MaybeRef<string>
  description?: MaybeRef<string>
  image?: MaybeRef<string>
}

export function usePageMeta(meta: PageMeta = {}) {
  const route = useRoute()
  const siteUrl = useRuntimeConfig().public.appUrl

  // these can be changed directly in the meta object
  const name = computed(() => get(meta.name))
  const description = computed(() => get(meta.description))
  const image = computed(() => [siteUrl, get(meta.image)].join(''))

  // these may use values from the route, so we need to watch for changes
  const title = ref()
  const url = ref()
  watch(
    () => route.path,
    () => {
      title.value = (route?.meta?.title || get(meta.title)) as string
      url.value = [siteUrl, route?.fullPath || ''].join('')
    },
    { immediate: true }
  )

  useHead({
    title: title,
    titleTemplate: `%s - ${name.value}`,
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: image,
      },
      {
        hid: 'og:image:width',
        property: 'og:image:width',
        content: 1200,
      },
      {
        hid: 'og:image:height',
        property: 'og:image:height',
        content: 630,
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: image,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: url,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: name,
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: url,
      },
    ],
  })
}
