import type { CompanyType, CompanyTypeRow } from '@openesg/supabase/models'
import {
  fetchCompanyLogo,
  fetchOwnedCompany,
  removeCompanyLogo,
  saveOwnedCompany,
  updateOwnedCompany,
  uploadCompanyLogo,
} from '~~/services/CompanyServices'

export const useCompanyStore = defineStore('company', () => {
  const company = ref<CompanyTypeRow | null>(null)
  const { uploadPending, uploadFile, removeFile, getFileUrl } = useFileUpload()

  const fetch = async () => {
    const response = await fetchOwnedCompany()
    company.value = response.data
    return response
  }

  const save = async (value: CompanyType['Insert']) => {
    const response = await saveOwnedCompany({ ...value })
    if (response.data) company.value = response.data

    return response
  }

  const update = async (value: CompanyType['Update']) => {
    const response = await updateOwnedCompany({ ...value })
    if (response.data) company.value = response.data

    return response
  }

  const uploadLogo = async (logo: File) => {
    if (company.value)
      return uploadFile(
        () => uploadCompanyLogo(company.value!.id, logo),
        (path: string) => update({ ...company.value!, logo_url: path })
      )
  }

  const getCompanyLogo = () =>
    getFileUrl(company.value?.logo_url, fetchCompanyLogo)

  const removeLogo = async () => {
    if (company.value?.logo_url)
      return removeFile(
        () => removeCompanyLogo(company.value!.logo_url!),
        () => update({ ...company.value!, logo_url: null })
      )
  }

  const hasSetupCompany = computed(() => !!company.value?.name)

  function $reset() {
    company.value = null
  }

  return {
    company,
    fetch,
    save,
    update,
    $reset,
    uploadLogo,
    removeLogo,
    getCompanyLogo,
    hasSetupCompany,
    uploadPending,
  }
})
