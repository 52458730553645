export const SET_TABLE_NAME = "sets";
export const SET_RESPONSE_TABLE_NAME = "set_responses";

export const EVIDENCE_TABLE_NAME = "evidences";

export const OPTION_TABLE_NAME = "options";

export const COMPANY_TABLE_NAME = "companies";

export const PROFILE_TABLE_NAME = "profiles";

export const PUBLISHED_TABLE_NAME = "published_scores";

export const QUESTION_TABLE_NAME = "questions";
export const QUESTION_RESPONSE_TABLE_NAME = "question_responses";

export const SECTION_TABLE_NAME = "sections";
export const SECTION_RESPONSE_TABLE_NAME = "section_responses";

export const SURVEY_TABLE_NAME = "surveys";
export const SURVEY_RESPONSE_TABLE_NAME = "survey_responses";

export const PRODUCT_TABLE_NAME = "products";

export const SUBSCRIPTION_TABLE_NAME = "subscriptions";
