import { Database } from '@openesg/supabase'
import { SUBSCRIPTION_TABLE_NAME } from '@openesg/supabase/constants/tableNames'
import { SubscriptionTypeResponse } from '@openesg/supabase/models'

export const fetchActiveSubscription = () => {
  return useSupabaseClient<Database>()
    .from(SUBSCRIPTION_TABLE_NAME)
    .select(`*`)
    .eq('status', 'active')
    .gte('expires_at', useNuxtApp().$dayjs().unix())
    .order('last_event_date', { ascending: false })
    .limit(1)
    .maybeSingle<SubscriptionTypeResponse>()
}

export const getSubscribedProduct = (productId: string) => {
  return $fetch(`/api/subscription/product/${productId}`, {
    method: 'GET',
  })
}
