import type { RealtimeChannel } from '@supabase/supabase-js'
import { SubscriptionTypeResponse } from '@openesg/supabase/models'
import { fetchActiveSubscription } from '~~/services/SubscriptionServices'

// create realtime channel for subscription updates
const createRealtimeChannel = (callback: () => void): RealtimeChannel => {
  const client = useSupabaseClient()
  return client
    .channel('public:subscriptions')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'subscriptions' },
      callback
    )
}

export const useSubscriptionStore = defineStore('subscription', () => {
  const activeSubscription = ref<SubscriptionTypeResponse | null>(null)

  const hasActiveSubscription = computed(() => {
    return activeSubscription.value !== null
  })

  const fetchActive = async () => {
    // handle getCurrent subscription call here
    const response = await fetchActiveSubscription()

    activeSubscription.value = response.data

    startSubscriptionCheck()
    return response
  }

  function $reset() {
    stopSubscriptionCheck()
    activeSubscription.value = null
  }

  // Realtime channel handling

  let realtimeChannel: RealtimeChannel | undefined = undefined

  const startSubscriptionCheck = () => {
    if (realtimeChannel) return
    console.warn('starting subscription check')
    realtimeChannel = createRealtimeChannel(() => {
      console.warn('active subscription changed on realtime channel!')
      fetchActive()
    })
    realtimeChannel.subscribe()
  }

  const stopSubscriptionCheck = () => {
    console.warn('stopping subscription check')
    if (!realtimeChannel) return
    realtimeChannel.unsubscribe()
    realtimeChannel = undefined
  }

  return {
    activeSubscription,
    fetchActive,
    $reset,
    hasActiveSubscription,
  }
})
