import revive_payload_client_YTGlmQWWT5 from "/buddy/openesg-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/buddy/openesg-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/buddy/openesg-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_qb1L118OuS from "/buddy/openesg-app/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_VTarsGAzgb from "/buddy/openesg-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/buddy/openesg-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/buddy/openesg-app/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/buddy/openesg-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_N1rIkEnC9Q from "/buddy/openesg-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/buddy/openesg-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_redirect_CRAPxt5OMX from "/buddy/openesg-app/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/buddy/openesg-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_userStateChangeListener_uAW0bxrpYw from "/buddy/openesg-app/apps/frontend/plugins/01.userStateChangeListener.ts";
import dayjs_pGiXRjcsJO from "/buddy/openesg-app/apps/frontend/plugins/dayjs.ts";
import vue3_gtm_client_TXyYnRX5RF from "/buddy/openesg-app/apps/frontend/plugins/vue3-gtm.client.ts";
import vue3_toastify_OGYNDsiW9E from "/buddy/openesg-app/apps/frontend/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  supabase_client_qb1L118OuS,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  auth_redirect_CRAPxt5OMX,
  chunk_reload_client_SeG0EjL5Ec,
  _01_userStateChangeListener_uAW0bxrpYw,
  dayjs_pGiXRjcsJO,
  vue3_gtm_client_TXyYnRX5RF,
  vue3_toastify_OGYNDsiW9E
]