import { default as _91productSlug_93mO65Z6TRtQMeta } from "/buddy/openesg-app/apps/frontend/pages/company/[companySlug]/[productSlug].vue?macro=true";
import { default as indexQWpOaRTOmSMeta } from "/buddy/openesg-app/apps/frontend/pages/company/[companySlug]/index.vue?macro=true";
import { default as index79Hgt0LQzvMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/company/index.vue?macro=true";
import { default as manage4lCq2OFsk0Meta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/company/manage.vue?macro=true";
import { default as _91setId_93RzdA0q89BcMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/company/questions/[setId].vue?macro=true";
import { default as indexwmfPt6EjG5Meta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/company/questions/index.vue?macro=true";
import { default as resultBPAh4tsRejMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/company/result.vue?macro=true";
import { default as indexCLu3nL3czJMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/index.vue?macro=true";
import { default as indexUayEKLOoPUMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/index.vue?macro=true";
import { default as _91setId_939HdPPoEsIlMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/questions/[setId].vue?macro=true";
import { default as indexiWwCOac5f2Meta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/questions/index.vue?macro=true";
import { default as resultn6Smx1ynGpMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/result.vue?macro=true";
import { default as indexbk4AXeqIfeMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/product/index.vue?macro=true";
import { default as indexu1jJDqvoqXMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/profile/index.vue?macro=true";
import { default as passwordWKfZcwlSiYMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/profile/password.vue?macro=true";
import { default as indexvMkUtzPsJvMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/subscribe/index.vue?macro=true";
import { default as successq5wr1VDcBTMeta } from "/buddy/openesg-app/apps/frontend/pages/dashboard/subscribe/success.vue?macro=true";
import { default as indexQGAtPuuMT6Meta } from "/buddy/openesg-app/apps/frontend/pages/index.vue?macro=true";
import { default as confirmB1h4vN1x40Meta } from "/buddy/openesg-app/apps/frontend/pages/login/confirm.vue?macro=true";
import { default as indexfbr0c7tfyoMeta } from "/buddy/openesg-app/apps/frontend/pages/login/index.vue?macro=true";
import { default as passwordEJfI3XCnY3Meta } from "/buddy/openesg-app/apps/frontend/pages/login/password.vue?macro=true";
import { default as tokenXVoY01t6U4Meta } from "/buddy/openesg-app/apps/frontend/pages/login/token.vue?macro=true";
import { default as indexZnBWtrzemTMeta } from "/buddy/openesg-app/apps/frontend/pages/onboarding/index.vue?macro=true";
import { default as index2r1BA5lIADMeta } from "/buddy/openesg-app/apps/frontend/pages/register/index.vue?macro=true";
export default [
  {
    name: _91productSlug_93mO65Z6TRtQMeta?.name ?? "company-companySlug-productSlug",
    path: _91productSlug_93mO65Z6TRtQMeta?.path ?? "/company/:companySlug()/:productSlug()",
    meta: _91productSlug_93mO65Z6TRtQMeta || {},
    alias: _91productSlug_93mO65Z6TRtQMeta?.alias || [],
    redirect: _91productSlug_93mO65Z6TRtQMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/company/[companySlug]/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: indexQWpOaRTOmSMeta?.name ?? "company-companySlug",
    path: indexQWpOaRTOmSMeta?.path ?? "/company/:companySlug()",
    meta: indexQWpOaRTOmSMeta || {},
    alias: indexQWpOaRTOmSMeta?.alias || [],
    redirect: indexQWpOaRTOmSMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/company/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: index79Hgt0LQzvMeta?.name ?? "dashboard-company",
    path: index79Hgt0LQzvMeta?.path ?? "/dashboard/company",
    meta: index79Hgt0LQzvMeta || {},
    alias: index79Hgt0LQzvMeta?.alias || [],
    redirect: index79Hgt0LQzvMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/company/index.vue").then(m => m.default || m)
  },
  {
    name: manage4lCq2OFsk0Meta?.name ?? "dashboard-company-manage",
    path: manage4lCq2OFsk0Meta?.path ?? "/dashboard/company/manage",
    meta: manage4lCq2OFsk0Meta || {},
    alias: manage4lCq2OFsk0Meta?.alias || [],
    redirect: manage4lCq2OFsk0Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/company/manage.vue").then(m => m.default || m)
  },
  {
    name: _91setId_93RzdA0q89BcMeta?.name ?? "dashboard-company-questions-setId",
    path: _91setId_93RzdA0q89BcMeta?.path ?? "/dashboard/company/questions/:setId()",
    meta: _91setId_93RzdA0q89BcMeta || {},
    alias: _91setId_93RzdA0q89BcMeta?.alias || [],
    redirect: _91setId_93RzdA0q89BcMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/company/questions/[setId].vue").then(m => m.default || m)
  },
  {
    name: indexwmfPt6EjG5Meta?.name ?? "dashboard-company-questions",
    path: indexwmfPt6EjG5Meta?.path ?? "/dashboard/company/questions",
    meta: indexwmfPt6EjG5Meta || {},
    alias: indexwmfPt6EjG5Meta?.alias || [],
    redirect: indexwmfPt6EjG5Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/company/questions/index.vue").then(m => m.default || m)
  },
  {
    name: resultBPAh4tsRejMeta?.name ?? "dashboard-company-result",
    path: resultBPAh4tsRejMeta?.path ?? "/dashboard/company/result",
    meta: resultBPAh4tsRejMeta || {},
    alias: resultBPAh4tsRejMeta?.alias || [],
    redirect: resultBPAh4tsRejMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/company/result.vue").then(m => m.default || m)
  },
  {
    name: indexCLu3nL3czJMeta?.name ?? "dashboard",
    path: indexCLu3nL3czJMeta?.path ?? "/dashboard",
    meta: indexCLu3nL3czJMeta || {},
    alias: indexCLu3nL3czJMeta?.alias || [],
    redirect: indexCLu3nL3czJMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexUayEKLOoPUMeta?.name ?? "dashboard-product-productId",
    path: indexUayEKLOoPUMeta?.path ?? "/dashboard/product/:productId()",
    meta: indexUayEKLOoPUMeta || {},
    alias: indexUayEKLOoPUMeta?.alias || [],
    redirect: indexUayEKLOoPUMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91setId_939HdPPoEsIlMeta?.name ?? "dashboard-product-productId-questions-setId",
    path: _91setId_939HdPPoEsIlMeta?.path ?? "/dashboard/product/:productId()/questions/:setId()",
    meta: _91setId_939HdPPoEsIlMeta || {},
    alias: _91setId_939HdPPoEsIlMeta?.alias || [],
    redirect: _91setId_939HdPPoEsIlMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/questions/[setId].vue").then(m => m.default || m)
  },
  {
    name: indexiWwCOac5f2Meta?.name ?? "dashboard-product-productId-questions",
    path: indexiWwCOac5f2Meta?.path ?? "/dashboard/product/:productId()/questions",
    meta: indexiWwCOac5f2Meta || {},
    alias: indexiWwCOac5f2Meta?.alias || [],
    redirect: indexiWwCOac5f2Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/questions/index.vue").then(m => m.default || m)
  },
  {
    name: resultn6Smx1ynGpMeta?.name ?? "dashboard-product-productId-result",
    path: resultn6Smx1ynGpMeta?.path ?? "/dashboard/product/:productId()/result",
    meta: resultn6Smx1ynGpMeta || {},
    alias: resultn6Smx1ynGpMeta?.alias || [],
    redirect: resultn6Smx1ynGpMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/product/[productId]/result.vue").then(m => m.default || m)
  },
  {
    name: indexbk4AXeqIfeMeta?.name ?? "dashboard-product",
    path: indexbk4AXeqIfeMeta?.path ?? "/dashboard/product",
    meta: indexbk4AXeqIfeMeta || {},
    alias: indexbk4AXeqIfeMeta?.alias || [],
    redirect: indexbk4AXeqIfeMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexu1jJDqvoqXMeta?.name ?? "dashboard-profile",
    path: indexu1jJDqvoqXMeta?.path ?? "/dashboard/profile",
    meta: indexu1jJDqvoqXMeta || {},
    alias: indexu1jJDqvoqXMeta?.alias || [],
    redirect: indexu1jJDqvoqXMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: passwordWKfZcwlSiYMeta?.name ?? "dashboard-profile-password",
    path: passwordWKfZcwlSiYMeta?.path ?? "/dashboard/profile/password",
    meta: passwordWKfZcwlSiYMeta || {},
    alias: passwordWKfZcwlSiYMeta?.alias || [],
    redirect: passwordWKfZcwlSiYMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/profile/password.vue").then(m => m.default || m)
  },
  {
    name: indexvMkUtzPsJvMeta?.name ?? "dashboard-subscribe",
    path: indexvMkUtzPsJvMeta?.path ?? "/dashboard/subscribe",
    meta: indexvMkUtzPsJvMeta || {},
    alias: indexvMkUtzPsJvMeta?.alias || [],
    redirect: indexvMkUtzPsJvMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: successq5wr1VDcBTMeta?.name ?? "dashboard-subscribe-success",
    path: successq5wr1VDcBTMeta?.path ?? "/dashboard/subscribe/success",
    meta: successq5wr1VDcBTMeta || {},
    alias: successq5wr1VDcBTMeta?.alias || [],
    redirect: successq5wr1VDcBTMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/dashboard/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: indexQGAtPuuMT6Meta?.name ?? "index",
    path: indexQGAtPuuMT6Meta?.path ?? "/",
    meta: indexQGAtPuuMT6Meta || {},
    alias: indexQGAtPuuMT6Meta?.alias || [],
    redirect: indexQGAtPuuMT6Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmB1h4vN1x40Meta?.name ?? "login-confirm",
    path: confirmB1h4vN1x40Meta?.path ?? "/login/confirm",
    meta: confirmB1h4vN1x40Meta || {},
    alias: confirmB1h4vN1x40Meta?.alias || [],
    redirect: confirmB1h4vN1x40Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/login/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexfbr0c7tfyoMeta?.name ?? "login",
    path: indexfbr0c7tfyoMeta?.path ?? "/login",
    meta: indexfbr0c7tfyoMeta || {},
    alias: indexfbr0c7tfyoMeta?.alias || [],
    redirect: indexfbr0c7tfyoMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: passwordEJfI3XCnY3Meta?.name ?? "login-password",
    path: passwordEJfI3XCnY3Meta?.path ?? "/login/password",
    meta: passwordEJfI3XCnY3Meta || {},
    alias: passwordEJfI3XCnY3Meta?.alias || [],
    redirect: passwordEJfI3XCnY3Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/login/password.vue").then(m => m.default || m)
  },
  {
    name: tokenXVoY01t6U4Meta?.name ?? "login-token",
    path: tokenXVoY01t6U4Meta?.path ?? "/login/token",
    meta: tokenXVoY01t6U4Meta || {},
    alias: tokenXVoY01t6U4Meta?.alias || [],
    redirect: tokenXVoY01t6U4Meta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/login/token.vue").then(m => m.default || m)
  },
  {
    name: indexZnBWtrzemTMeta?.name ?? "onboarding",
    path: indexZnBWtrzemTMeta?.path ?? "/onboarding",
    meta: indexZnBWtrzemTMeta || {},
    alias: indexZnBWtrzemTMeta?.alias || [],
    redirect: indexZnBWtrzemTMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: index2r1BA5lIADMeta?.name ?? "register",
    path: index2r1BA5lIADMeta?.path ?? "/register",
    meta: index2r1BA5lIADMeta || {},
    alias: index2r1BA5lIADMeta?.alias || [],
    redirect: index2r1BA5lIADMeta?.redirect || undefined,
    component: () => import("/buddy/openesg-app/apps/frontend/pages/register/index.vue").then(m => m.default || m)
  }
]