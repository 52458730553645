import { useCompanyStore } from '~~/stores/company.store'
import { useProfileStore } from '~~/stores/profile.store'
import { useSubscriptionStore } from '~~/stores/subscription.store'

export default defineNuxtPlugin((nuxtApp) => {
  /**
   * This plugin is responsible for listening to user state changes,
   * and redirecting the user to the appropriate page.
   */

  const user = useSupabaseUser()
  const companyStore = useCompanyStore()
  const profileStore = useProfileStore()
  const subscriptionStore = useSubscriptionStore()

  const { hasSetupCompany } = storeToRefs(companyStore)
  const { hasSetupProfile } = storeToRefs(profileStore)

  const getRedirectPath = (path: string) => {
    if (!hasSetupProfile.value || !hasSetupCompany.value) {
      return '/onboarding'
    }
    if (path === '/login' || path === '/login/confirm')
      return (useRoute().query?.next as string) || '/dashboard'
    return path
  }

  const handleSignIn = async () => {
    await profileStore.fetch()
    await companyStore.fetch()
    await subscriptionStore.fetchActive()
    const path = useRoute().path
    const redirectPath = getRedirectPath(path)
    if (path !== redirectPath) {
      await navigateTo(redirectPath)
    }
  }

  const handleSignOut = async () => {
    companyStore.$reset()
    profileStore.$reset()
    subscriptionStore.$reset()
    await navigateTo('/login')
  }

  watch(
    user,
    async (current, prev) => {
      // if we have a current user, and didn't have one before, they are logging in
      if (current && !prev) {
        if (process.client) {
          await handleSignIn()
        }
      }
      // if we had a prev user, and now we don't, they are logging out
      if (prev && !current) {
        await handleSignOut()
      }
    },
    { immediate: true }
  )
})
