import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 2500,
    limit: 3,
    closeButton: true,
    theme: 'light',
    transition: toast.TRANSITIONS.SLIDE,
  })

  return {
    provide: {
      toast,
    },
  }
})
