import {
  fetchUserProfile,
  updateUserProfile,
} from '~~/services/ProfileServices'

import type { ProfileType } from '@openesg/supabase/models'
import { useSubscriptionStore } from './subscription.store'

export const useProfileStore = defineStore('profile', () => {
  const profile = ref<ProfileType['Row'] | null>(null)

  const fetch = async () => {
    const response = await fetchUserProfile()
    profile.value = response.data
    return response
  }

  const update = async (value: ProfileType['Insert']) => {
    const response = await updateUserProfile(value)
    profile.value = response.data
    return response
  }

  const hasSetupProfile = computed(() => !!profile.value?.name)

  const isProUser = computed(() => {
    // TODO: check pro user against monthly payment
    const { activeSubscription } = useSubscriptionStore()
    return !!activeSubscription || false
  })

  function $reset() {
    profile.value = null
  }

  return { profile, fetch, update, $reset, hasSetupProfile, isProUser }
})
