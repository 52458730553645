import type { VueGtmUseOptions } from '@gtm-support/vue-gtm'
import { createGtm } from '@gtm-support/vue-gtm'

export interface GTMConfig extends VueGtmUseOptions {}

const configDefault: Omit<GTMConfig, 'id' | 'vueRouter'> = {
  defer: false,
  compatibility: false,
  enabled: true,
  debug: false,
  loadScript: true,
  trackOnNextTick: false,
}

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { gtm: configRuntime },
  } = useRuntimeConfig()

  if (!configRuntime.id)
    return console.debug('GTM ID not found, skipping GTM plugin load')

  const config: GTMConfig = {
    ...configDefault,
    ...configRuntime,
    vueRouter: useRouter() as VueGtmUseOptions['vueRouter'],
  }

  nuxtApp.vueApp.use(createGtm(config))
  console.debug('GTM plugin loaded')
})
